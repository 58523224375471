import { Alert } from "@/services/notification";
// import { WolfApiData } from '@/components/wrappers/molds/TableOptions';
// import { Process } from '@/store/modules/process/process';
import {
  parseStrEnUsDate,
  parseDateEnUs,
  parseTimeToDate,
  parseDateToTimeString,
  convertDataPtBr,
} from "@/utils";

export class LicitacaoService {
  store: any;
  alert: Alert;
  totalItensProcess: any = 0;

  constructor(store: any) {
    this.store = store;
    this.alert = new Alert(this.store);
  }

  processListReceive(list: any) {
    return list.map((item: any) => {
      item.dataabertura = convertDataPtBr(item.dataabertura);
      item.datapublicacao = convertDataPtBr(item.datapublicacao);
      item.fksituacao = this.getSituacaoTitle(item.fksituacao);
      return item;
    });
  }

  processListAnexosReceive(list: any) {
    return list.map((item: any) => {
      item.data = new Date(item.data);
      // item.data = parseStrEnUsDate(item.data);
      return item;
    });
  }

  processToSendData(item: any) {
    item.dataabertura = parseDateEnUs(item.dataabertura);
    item.horaabertura = parseDateToTimeString(item.horaabertura);
    item.datapublicacao = parseDateEnUs(item.datapublicacao);
    return item;
  }

  processReceiveData(item: any) {
    item.dataabertura = parseStrEnUsDate(item.dataabertura);
    item.datapublicacao = parseStrEnUsDate(item.datapublicacao);
    item.horaabertura = parseTimeToDate(item.horaabertura);
    return item;
  }

  getModalidadeTitle(modalidade: any) {
    const res: any = this.getModalidadesValues().find((item: any) => {
      return item.value == modalidade;
    });
    console.log(res);
    if (res == undefined) {
      return modalidade;
    }
    return res.title;
  }

  getModalidadesValues() {
    return [
      { value: 1, title: "Convite" },
      { value: 2, title: "Dispensa de Licitação" },
      { value: 3, title: "Tomada de Preços" },
      { value: 4, title: "Pregão Presencial" },
      { value: 5, title: "Leilão" },
      { value: 6, title: "Concorrência" },
      { value: 7, title: "Pregão eletrônico" },
      { value: 8, title: "Chamamento Pública" },
      { value: 9, title: "Chamada Pública" },
      { value: 10, title: "Convite" },
      { value: 11, title: "Inexigibilidade" },
      { value: 12, title: "Credenciamento" },
    ];
  }

  getSituacaoTitle(situacao: any) {
    const res: any = this.getSituacoesValues().find((item: any) => {
      return item.value == situacao;
    });
    if (res == undefined) {
      return situacao;
    }
    if (res.html != undefined) {
      return res.html;
    }
    return res.title;
  }

  getSituacoesValues() {
    return [
      {
        value: 1,
        title: "Aberta",
        html: '<span class="has-text-success">Aberta</span>',
      },
      { value: 2, title: "Anulada" },
      { value: 3, title: "Revogada" },
      {
        value: 4,
        title: "Encerrada",
        html: '<span class="has-text-danger">Encerrada</span>',
      },
      { value: 5, title: "Suspensa" },
    ];
  }

  getStatusName(status: string): string {
    switch (status) {
      case "0":
        return "Desativado";
      case "1":
        return `<span class="has-text-warning-dark is-size-7"> <span class="icon"> <i class="mdi mdi-file-cabinet"></i>
        </span> Arquivado </span>`;
      case "2":
        return `<span class="has-text-success is-size-7"><span class="icon"> <i class="fas fa-envelope-open"></i>
        </span> Recebido </span>`;
      case "3":
        return `<span class="has-text-danger is-size-7"><span class="icon"> <i class="fas fa-envelope"></i>
        </span> Não Recebido </span>`;
      case "4":
        return '<span class="has-text-warning-dark"> Retornado </span>';
      case "5":
        return `<span class="has-text-success-dark is-size-7"> 
        <span class="icon"> <i class="fas fa-check"></i>
      </span> Solucionado </span>`;
      case "6":
        return '<span class="has-text-success-dark">  Finalizado </span>';
      case "7":
        return "A importar";
      default:
        return status;
    }
  }
}
